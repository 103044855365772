import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import style from "./portfolio-index.module.css"

const PortfolioList = ({ posts }) => {
  return (
    <ul className={style.portlist}>
      {posts.map(({ node }, index) => (
        <li key={index}>
          <Link to={node.fields.slug}>
            {node.frontmatter.featimg && (
              <figure className={style.itemimage}>
                <Img
                  fluid={node.frontmatter.featimg.childImageSharp.fluid}
                  alt={node.frontmatter.title}
                />
              </figure>
            )}
            <div className={style.itemcontent}>
              <h2>{node.frontmatter.title}</h2>
              <div className={style.meta}>
                <div>{node.frontmatter.author}</div>
                <div>
                  {new Date(node.frontmatter.date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </div>
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default PortfolioList
