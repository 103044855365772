import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PortfolioItem from "../components/portfolio-list"
import Pagination from "../components/pagination"

const PortfolioIndex = ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location}>
      <h1>Portfolio</h1>
      <PortfolioItem posts={posts} />

      <Pagination pageContext={pageContext} />
    </Layout>
  )
}

export default PortfolioIndex

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "portfolio" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            author
            featimg {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
